<!-- eslint-disable vue/no-v-html -->
<template>
  <div ref="rootElRef" class="hot-product">
    <div class="hot-product-wrapper wrapper">
      <div class="hot-product-inner">
        <div class="hot-product__content">
          <div
            v-if="content.headline"
            class="hot-product__content__headline"
            v-html="content.headline"
          ></div>

          <h3
            class="hot-product__content__title h2"
            v-html="content.title"
          ></h3>

          <HtmlText
            v-if="content.text"
            class="hot-product__content__text"
            :content="content.text"
          />

          <Action
            v-if="content.link"
            :content="content.link"
            class="hot-product__content__link"
          />
        </div>

        <div
          v-if="content.pictures && content.pictures.length > 1"
          class="hot-product__media"
        >
          <PicturesBasicSlider
            :content="content.pictures"
          ></PicturesBasicSlider>
        </div>

        <div v-else-if="content.pictures" class="hot-product__media">
          <div>
            <GPicture
              class="hot-product__media__picture cover"
              :content="content.pictures[0]"
              sizes="50vw"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import type { Link, Picture } from '@/types'

export interface HotProduct {
  headline: string
  title: string
  text?: string
  pictures: Picture[]
  link: Link
}
</script>

<script setup lang="ts">
import gsap from 'gsap'
import { onMounted, ref } from 'vue'

import PicturesBasicSlider from '@/components/slider/PicturesBasic.vue'
import { useAppear } from '@/utils/aware'

import type { PropType } from 'vue'

const rootElRef = ref<HTMLElement | null>(null)

defineProps({
  content: {
    type: Object as PropType<HotProduct>,
    required: true,
  },
})

onMounted(() => {
  if (rootElRef.value) {
    const tl = gsap.timeline({})
    tl.set(rootElRef.value, {
      opacity: 0,
    })
  }
})

useAppear(
  rootElRef,
  ({ isInViewport }) => {
    if (isInViewport) {
      const tl = gsap.timeline()

      tl.to(rootElRef.value, {
        duration: 1.5,
        opacity: 1,
        ease: 'Power1.easeOut',
      })
    }
  },
  { rootMargin: '-25% 0px', once: true }
)
</script>

<style lang="scss" scoped>
.hot-product {
  position: relative;
  overflow: hidden;
  padding: $spacing * 2 0;
}

.hot-product-inner {
  @extend %br-default;

  overflow: hidden;
  background: $c-beige;

  @include mq(m) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }
}

.hot-product__media {
  @extend %br-default;

  position: relative;
  background-color: $c-white;
  border: 1px solid $c-grey-light;

  > div {
    @extend %br-default;

    position: relative;
    overflow: hidden;
    width: 100%;
    aspect-ratio: 93/72;
  }

  @include mq($until: m) {
    margin-top: 4.8rem;
  }

  @include mq(m) {
    width: col(14);
  }
}

.hot-product__media__picture {
  @extend %br-default;
  @include get-all-space;
}

.hot-product__content {
  padding: $spacing col(1);

  @include mq($until: m) {
    padding-top: $spacing * 1.5;
  }

  @include mq(m) {
    width: col(10);
  }
}

.hot-product__content__headline {
  @extend %fw-semi;

  margin-bottom: 0.4rem;
  color: $c-green-abr;
  font-size: 2rem;
  line-height: 2.8rem;
}

.hot-product__content__title {
  @extend %fraunces-spec;

  margin-top: 0;
  margin-bottom: 1.6rem;
  color: $c-green-abr;
}

.hot-product__content__text {
  :deep(p) {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.hot-product__content__link {
  margin-top: 3.6rem;
}
</style>
